.home{
    display: flex;
}

.img-fluid {
  opacity: 0.75;
}

.text_p {
    position: absolute;
    width: fit-content;
    transform: translateX(20%) translateY(-120%);
  }
  
  .bloq_a {
    width: fit-content;
    transform: translateX(40%) translateY(15%)
  }
  